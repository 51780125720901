import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import md5 from 'md5'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    domain: md5(window.location.hostname),
    cartItems: localStorage.getItem('pagesId') ? JSON.parse(localStorage.getItem('pagesId')) : [],
    pages: 0,
    projects: [],
    counts: 0,
    statusSearchProjects: 0, //0 Processing / 1 Success, 2 Empty
    project_name: '',
    settings: {
      page: 1,
    },
    filterTools: {
      bathrooms: 0,
      floors: 1,
      garage_type: 0,
      basement: 0,
      rooms: 0,
      roof_type: 0,
      square: [],
      mansard: 0,
      materials: [1, 2, 3, 4, 5, 8],
      dimension: [],
      type_project: 1,
      styles: [],
      sort: 'popular'
    },


    cdn: {},

    socials: [
      {
        img: '/img/4.png',
        src: 'https://t.me/foodmiles',
        target: '',
      },

      {
        img: '/img/whatsapp.png',
        src: 'f',
        target: '',
      },

      {
        img: '/img/viber.png',
        src: '',
        target: 'f',
      },
    ],


    manager: {
      avatar: '/img/spec.jpg',
      fio: 'Леонид',
    },

    contacts: {
      phone: '+7 (984) 93-30-18',
      email: 'gidrevich.a@foodmiles.ru',
    },

    form: {
      politica: {
        val: '/politika-konfidencialnosti',
        target: '_blank',
      },
    },

    api: {
      url: 'https://api.vevanta.com/api/lead/add'
    },

    config: {
      yandex: {
        id: '',
      },

      form: {
        urls: [
          {url: 'mail/vevanta', keys: []}
        ],
        bitrix: {
          sourceId: '',
          staffId: '',
          companyId: '',
        }

      }
    }

  },

  mutations: {
    SET_FILTER(context, data) {
      console.log('dat-', data);
      if (Object.keys(data).length) {
        context.filterTools = data
      }
    },
    SET_PAGED (context, page) {
      context.settings.page = page
    },

    ADD_PAGED (context) {
      ++context.settings.page
    },

    SET_SORT (context, sort) {
      context.filterTools.sort = sort
    },

    SET_MANAGER (context, data) {
      console.log("PRIVET")
      context.manager = data
    },
  },

  actions: {
    SET_SETTINGS(context, data) {
      console.log('data', data)
      context.state.settings = data
      console.log(  context.state.settings )
    },


    SET_MESSANGERS(context, data) {
      context.state.socials = data
    },

    SET_CDN(context, data) {
      context.state.cdn = data
    },

    SET_CONFIG (context, data) {
      context.state.config = data
    },



    SET_API (context, data) {
      context.state.api = data
    },

    SET_FORM (context, data) {
      context.state.form = data
    },

    SET_CONTACTS (context, data) {
      context.state.contacts = data
    },

    SET_STATUS_SEARCH(context, status) {
      context.state.statusSearchProjects = status
    },

    GET_PROJECTS (context, filter = {}) {
      context.state.statusSearchProjects = 0
      if (Object.keys(filter).length) {
        context.state.filterTools = filter
      }

      context.state.projects = []
      axios.post(`https://api.vevanta.ru/v1/projects`, {
        filter: context.state.filterTools,
        settings: context.state.settings
      })
        .then(rs => {
          if (rs.data.projects.length) {
            context.state.counts = rs.data.counts
            context.state.pages = rs.data.pages
            context.state.projects = rs.data.projects

            if (context.state.settings.page >= context.state.pages) {
              context.state.statusSearchProjects = 3
            } else {
              context.state.statusSearchProjects = 1
            }


          } else {
            context.state.counts = 0
            context.state.pages = 0
            context.state.projects = []
            context.state.statusSearchProjects = 2
          }
        })
    },

    MORE_PROJECTS (context, filter = {}) {
      context.state.statusSearchProjects = 0

      if (Object.keys(filter).length) {
        context.state.filterTools = filter
      }
      axios.post(`https://api.vevanta.ru/v1/projects`, {
        filter: context.state.filterTools,
        settings: context.state.settings
      })
        .then(rs => {
          if (rs.data.projects.length) {
            context.state.counts = rs.data.counts
            context.state.pages = rs.data.pages
            context.state.projects.push(...rs.data.projects)
            context.state.statusSearchProjects = 1

            if (context.state.settings.page >= context.state.pages) {
              context.state.statusSearchProjects = 3
            } else {
              context.state.statusSearchProjects = 1
            }
          } else {
            context.state.pages = 0
            context.state.projects = []
            context.state.statusSearchProjects = 3
          }
        })
    },
  },
  modules: {
  }
})
